<template>
  <!-- eslint-disable vue/no-static-inline-styles -->
  <v-form ref="form" class="user-form d-flex flex-column" :disabled="isPending">
    <fictitious-login-form />

    <v-sheet class="px-4 pt-8 pb-1" :elevation="0">
      <v-text-field
        id="true-login"
        v-model="formModel.login"
        class="required"
        type="email"
        autocomplete="username"
        label="Почта"
        :rules="formRules.login"
        dense
        filled
      />

      <password-input
        id="true-password"
        v-model="formModel.password"
        class="required"
        autocomplete="new-password"
        label="Пароль"
        :rules="formRules.password"
        dense
        filled
      />
    </v-sheet>

    <v-sheet class="mt-3 px-4 pt-8 pb-1" :elevation="0">
      <v-text-field id="ogrn" v-model="formModel.ogrn" label="ОГРН" :rules="formRules.ogrn" dense filled />

      <v-text-field id="inn" v-model="formModel.inn" class="required" label="ИНН" :rules="formRules.inn" dense filled />

      <v-text-field
        id="full_name"
        v-model="formModel.full_name"
        class="required"
        style="grid-column: 4 / 7"
        autocomplete="organization"
        label="Полное наименование"
        :rules="formRules.requiredProperty"
        dense
        filled
      />

      <v-text-field
        id="director"
        v-model="formModel.director"
        style="grid-column: 1 / 4"
        autocomplete="name"
        label="Генеральный директор"
        dense
        filled
      />

      <v-text-field
        id="short_name"
        v-model="formModel.short_name"
        style="grid-column: 4 / 7"
        autocomplete="organization"
        label="Сокращенное наименование"
        dense
        filled
      />

      <v-text-field
        id="phone"
        v-model="formModel.phone"
        class="required"
        autocomplete="tel"
        label="Телефон"
        :rules="formRules.requiredProperty"
        dense
        filled
      />

      <v-text-field
        id="legal_address"
        v-model="formModel.legal_address"
        style="grid-column: 4 / 7"
        label="Юридический адрес"
        dense
        filled
      />

      <v-text-field
        id="address"
        v-model="formModel.address"
        style="grid-column: 1 / 4"
        label="Местонахождение организации"
        dense
        filled
      />

      <v-text-field
        id="mail_address"
        v-model="formModel.mail_address"
        style="grid-column: 4 / 7"
        label="Почтовый адрес"
        dense
        filled
      />
    </v-sheet>

    <v-btn class="mt-8" color="primary" :disabled="isPending" :loading="isPending" depressed @click="register()">
      Далее
    </v-btn>
  </v-form>
</template>

<script>
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import i18n from '@/i18n'
  import { emailRule, innRule, ogrnRule, requiredPropertyRule } from '@/utils/forms'

  const formRules = {
    login: [(value) => !!value || i18n.t('auth.errors.usernameIsRequired'), emailRule],
    password: [(value) => !!value || i18n.t('auth.errors.passwordIsRequired')],
    requiredProperty: [requiredPropertyRule],
    inn: [requiredPropertyRule, innRule],
    ogrn: [ogrnRule]
  }

  export default {
    name: 'UserDataForm',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        formRules,
        formModel: {
          login: null,
          password: null,
          ogrn: null,
          inn: null,
          full_name: null,
          director: null,
          short_name: null,
          phone: null,
          legal_address: null,
          address: null,
          mail_address: null
        },
        registrationCode: null
      }
    },

    created() {
      this.checkRoute()
    },

    methods: {
      checkRoute() {
        this.registrationCode = this.$route.query.registration_code ?? null
        this.formModel.login = this.$route.query.email ?? null
        this.$router.replace({ query: null })
      },

      @handlePendingTask()
      async register() {
        if (!this.$refs.form.validate()) {
          return
        }

        await this.$api.backend.users.register_user({
          user_data: this.formModel,
          registration_code: this.registrationCode ?? undefined
        })
        await this.$auth.afterLogin()

        this.$emit('next')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '~@/styles/variables.scss';

  .user-form {
    .v-sheet {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 12px;
    }
  }
</style>
