<template>
  <div>
    <v-sheet class="px-4 py-8" :elevation="0">
      <template v-if="$auth.user.pending_email_confirmation">
        <p>Вам на почту было отправлено письмо для ее подтверждения.</p>

        <p class="mb-0">
          Пройдите по ссылке из письма для завершения регистрации и получения доступа ко всем функциям системы.
        </p>

        <div class="d-flex mt-10">
          <v-spacer />

          <v-btn :disabled="isPending" :loading="isPending" color="primary" text @click="sendEmailConfirmation()">
            Отправить письмо еще раз
          </v-btn>
        </div>
      </template>

      <p v-else class="mb-0">Ваша почта подтверждена.</p>
    </v-sheet>

    <v-btn
      class="w-100 mt-8"
      :disabled="isPending || $auth.user.pending_email_confirmation"
      color="primary"
      depressed
      @click="$emit('next')"
    >
      Далее
    </v-btn>
  </div>
</template>

<script>
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { showMessage } from '@/utils/messages'

  export default {
    name: 'ConfirmEmail',
    mixins: [PendingTasksManagerMixin],

    watch: {
      $route: {
        handler: 'checkRoute',
        immediate: true
      }
    },

    methods: {
      @handlePendingTask()
      async sendEmailConfirmation() {
        await this.$api.backend.users.send_email_confirmation()
        showMessage('Письмо было выслано повторно')
      },

      @handlePendingTask()
      async checkRoute() {
        const confirmationCode = this.$route.query.confirmation_code
        if (!confirmationCode) {
          return
        }

        this.$router.replace({ query: null })

        await this.$api.backend.users.confirm_email({ confirmation_code: confirmationCode })
        await this.$auth.updateUser()
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
