<template>
  <!-- eslint-disable vue/no-static-inline-styles -->
  <page-container class="user-registration d-flex justify-center" title="Регистрация">
    <div class="block-subtitle mt-3">{{ stepsTitle }}</div>

    <user-data-form
      v-if="step === RegistrationStep.USER_DATA"
      class="mt-3"
      @next="moveToStep(RegistrationStep.CONFIRM_EMAIL)"
    />

    <confirm-email
      v-else-if="step === RegistrationStep.CONFIRM_EMAIL"
      class="mt-3"
      @next="moveToStep(RegistrationStep.BIND_TO_RTS)"
    />

    <bind-to-rts v-else-if="step === RegistrationStep.BIND_TO_RTS" class="mt-3" />
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import { RegistrationStep } from './userRegistration'
  import UserDataForm from './UserDataForm'
  import ConfirmEmail from './ConfirmEmail'
  import BindToRts from './BindToRts'

  const RegistrationStepToOrder = _.mapValues(
    _.invert([RegistrationStep.USER_DATA, RegistrationStep.CONFIRM_EMAIL, RegistrationStep.BIND_TO_RTS]),
    Number
  )

  export default {
    name: 'UserRegistrationView',
    components: { BindToRts, ConfirmEmail, UserDataForm },

    props: {
      step: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        RegistrationStep
      }
    },

    computed: {
      stepsTitle() {
        const stepOrder = RegistrationStepToOrder[this.step] + 1
        return `Шаг ${stepOrder} из ${Object.keys(RegistrationStep).length}`
      }
    },

    watch: {
      $route: {
        handler: 'checkRoute',
        immediate: true
      }
    },

    created() {
      this.$auth.$on('logout', this.checkRoute)
    },

    methods: {
      checkRoute() {
        if (this.step && !Object.values(RegistrationStep).includes(this.step)) {
          this.$router.goToNotFoundErrorPage()
          return
        }

        const forbiddenState =
          ((!this.step || this.step === RegistrationStep.USER_DATA) && this.$auth.authenticated) ||
          ((this.step === RegistrationStep.CONFIRM_EMAIL || this.step === RegistrationStep.BIND_TO_RTS) &&
            !this.$auth.authenticated)
        if (forbiddenState) {
          this.$router.goToForbiddenErrorPage()
          return
        }

        if (!this.step) {
          this.moveToStep(RegistrationStep.USER_DATA, true)
        }
      },

      moveToStep(step, replace = false) {
        const routePath = `/registration/${step}`
        const location = { path: routePath, query: { ...this.$route.query } }
        replace ? this.$router.replace(location) : this.$router.push(location)
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
