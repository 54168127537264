import { objectFromKeys } from '../utils/func'

// WARNING: legacy decorator (stage 1)
// NOTE: legacy decorator are enabled by default in @vue/cli-plugin-babel/preset
export function handlePendingTask(scope) {
  return function (target, name, descriptor) {
    const original = descriptor.value
    descriptor.value = async function (...args) {
      const task = original.apply(this, args)
      return this.handlePendingTask(task, scope)
    }
  }
}

const DEFAULT_SCOPE = 'default'

export default {
  name: 'PendingTasksManagerMixin',

  data() {
    return {
      pendingTasksState: {
        [DEFAULT_SCOPE]: 0
      },
      isComponentActivated: true
    }
  },

  computed: {
    isPending() {
      if (!this.isComponentActivated) {
        return false
      }
      return this.pendingTasksState[DEFAULT_SCOPE] > 0
    },

    pendingTasks() {
      if (!this.isComponentActivated) {
        return objectFromKeys(Object.keys(this.pendingTasksState), false)
      }

      return Object.entries(this.pendingTasksState).reduce((acc, [scope, tasksNumber]) => {
        acc[scope] = tasksNumber > 0
        return acc
      }, {})
    },

    hasPendingTasks() {
      if (!this.isComponentActivated) {
        return false
      }
      return Object.values(this.pendingTasksState).some(Boolean)
    }
  },

  activated() {
    this.isComponentActivated = true
  },

  deactivated() {
    this.isComponentActivated = false
  },

  beforeRouteLeave(to, from, next) {
    this.resetPendingTasks()
    next()
  },

  methods: {
    resetPendingTasks() {
      this.pendingTasksState = objectFromKeys(Object.keys(this.pendingTasksState), 0)
    },

    /**
     * Delay time enables a loading indicator to run continuously between tasks
     * @param {*} task
     * @param {PropertyKey} [scope]
     * @returns {Promise<*>}
     */
    async handlePendingTask(task, scope = DEFAULT_SCOPE) {
      let numberOfTasks = this.pendingTasksState[scope] || 0
      this.$set(this.pendingTasksState, scope, numberOfTasks + 1)

      try {
        return await task
      } finally {
        numberOfTasks = this.pendingTasksState[scope] || 0
        if (numberOfTasks) {
          this.$set(this.pendingTasksState, scope, numberOfTasks - 1)
        }
      }
    }
  }
}
