<template>
  <div>
    <v-sheet class="px-4 py-8" :elevation="0">
      <p class="mb-0">
        <template v-if="$auth.user.has_rts_binding">Ваш профиль привязан к РТС-тендер.</template>
        <template v-else>Для получения доступа к функциям РТС-тендер необходимо привязать профиль.</template>
      </p>
    </v-sheet>

    <v-btn
      v-if="!$auth.user.has_rts_binding"
      class="w-100 mt-8 text--white"
      :href="rtsLoginUrl"
      rel="noreferrer noopener"
      depressed
      color="rtsPrimary"
      @click.prevent="handleBindUserToRts()"
    >
      Привязать профиль к учетной записи РТС-тендер
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'BindToRts',

    computed: {
      rtsLoginUrl() {
        return this.$auth.getRtsLoginUrl()
      }
    },

    watch: {
      $route: {
        handler: 'checkRoute',
        immediate: true
      }
    },

    methods: {
      handleBindUserToRts() {
        this.$auth.redirectToRtsLoginForm(this.$route.path)
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
